<script setup lang="ts">
import { useIntervalFn, useRefHistory } from '@vueuse/core'
import { computed, ref } from 'vue'

const trpc = useTrpc()

const { data } = await useLazyAsyncData(() => trpc.quotes.getQuotes.query())

const quotes = computed(() => data.value?.data ?? [])

const currentIndex = ref(0)
const { history } = useRefHistory(currentIndex, {
  capacity: quotes.value.length,
})

const { pause, resume } = useIntervalFn(() => {
  if (currentIndex.value < quotes.value.length - 1)
    currentIndex.value += 1
  else currentIndex.value = 0
}, 5000)

function nextQuote() {
  pause()
  if (currentIndex.value < quotes.value.length - 1)
    currentIndex.value += 1
  else currentIndex.value = 0
  resume()
}

function prevQuote() {
  pause()
  if (currentIndex.value > 0)
    currentIndex.value -= 1
  else currentIndex.value = quotes.value.length - 1
  resume()
}

const isNextQuote = computed(() => {
  const previous = history.value[1]
  return previous !== undefined && previous.snapshot < currentIndex.value
})

const $img = useImage()
const imgFondTemoignages = $img('/img/fond-temoignages.png', {
  format: 'webp',
  width: 1200,
  quality: 80,
})
const cssImgFondTemoignages = computed(() => `url(${imgFondTemoignages})`)
</script>

<template>
  <div class="relative w-full">
    <div class="w-full flex items-center">
      <div
        :style="{ backgroundImage: cssImgFondTemoignages }"
        class="mb-16 mt-14 w-full rounded-xl bg-cover bg-center"
      >
        <div class="w-full flex items-center rounded-xl bg-void bg-opacity-60 px-8 py-8 lg:px-20">
          <Transition :name="isNextQuote ? 'slide-next' : 'slide-previous'" mode="out-in">
            <div :key="currentIndex" class="mx-2 min-h-150 grow rounded rounded-xl p-1 xl:grid xl:grid-cols-9 lg:mx-6 lg:min-h-100 lg:px-8 lg:py-4">
              <div class="flex flex-col xl:col-span-7 xl:pr-20">
                <p class="mb-2 mt-5 text-2xl font-semibold font-laviossa">
                  {{ quotes[currentIndex]?.firstName }} {{ quotes[currentIndex]?.lastName }}
                </p>
                <p class="mb-4 mt-10 text-justify text-xl text-lavender leading-8 font-gravity">
                  {{ quotes[currentIndex]?.quote }}
                </p>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </div>

    <div class="absolute top-0 grid grid-cols-20 h-full w-full shrink-0 gap-4">
      <div class="col-span-2 flex items-center justify-center">
        <button
          class="mb-16 rounded-full p-2 text-white transition-all lt-lg:ml-8 hover:bg-lavender-600 focus:outline-none"
          @click="prevQuote"
        >
          <span class="i-mdi:chevron-left block h-8 w-8" />
        </button>
      </div>

      <div class="col-offset-6 col-span-4 col-start-15 flex flex-col items-center justify-between bg-yellow from-lemon-500 to-sunset bg-gradient-to-b lt-xl:invisible 2xl:mx-6">
        <div class="mx-auto block h-20 w-[3px] bg-sunset" />

        <div>
          <p class="text-center text-lg text-sunset font-bold tracking-widest font-ubuntu uppercase">
            Tous nos
          </p>
          <p class="text-center text-2xl text-black font-laviossa">
            témoignages
          </p>
        </div>

        <NuxtLink class="rounded-full bg-black" to="/temoignages">
          <span class="i-mdi:plus block h-10 w-10 from-lemon-500 to-sunset bg-gradient-to-b" />
        </NuxtLink>

        <div class="h-65" />
      </div>

      <div class="col-span-2 mr-0 flex items-center justify-center lg:mr-3 xl:mr-0">
        <button
          class="mb-16 rounded-full p-2 text-white transition-all hover:bg-lavender-600 focus:outline-none"
          @click="nextQuote"
        >
          <span class="i-mdi:chevron-right block h-8 w-8" />
        </button>
      </div>
    </div>
  </div>
</template>
